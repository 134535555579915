import React from "react";
import "./Footer.css";
import facebook from "../../assets/images/facebook.svg";
import insta from "../../assets/images/insta.svg";
import youtube from "../../assets/images/youtube.svg";
import twitter from "../../assets/images/twitter.svg";
import linkdin from "../../assets/images/linkdn.svg";
import email from "../../assets/images/phone.svg";
import phone from "../../assets/images/mail.svg";
import location from "../../assets/images/location.svg";
import google from "../../assets/images/googleplay.svg";
import appStore from "../../assets/images/applestore.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="container mb-5 mt-5">
        <div className="footer-container footer-container-s">
          <div className="text-start footer-separate-first-container">
            <Link to={"/"} className="footer-heading">
              <h2>i</h2>
              <h2 className="heading-clr">Ride</h2>
            </Link>
            <p className="helping-you-to-get">
              Find your perfect car in Pakistan
              <br></br>
              effortlessly and quickly with
              <br></br>
              our platform.
              <br></br>
            </p>
            <div className="footer-icons">
              {/* <img className="vector-11" alt="Vector" src={facebook} />
              <img className="vector-11" alt="Vector" src={insta} />
              <img className="vector-11" alt="Vector" src={twitter} />
              <img className="vector-11" alt="Vector" src={youtube} />
              <img className="vector-11" alt="Vector" src={linkdin} /> */}
            </div>
            <div className="d-flex mt-4 gap-4 appstore-buttons">
              {/* <a
                href="https://play.google.com/store/apps/app-id"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={google} alt="google" />
              </a> */}
              {/* <a
                href="https://itunes.apple.com/app/app-id"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={appStore} alt="appstore" />
              </a> */}
            </div>
          </div>
          {/* <div className="footer-col footer-separate-second-container">
            <h5 className="heading-clr">Product</h5>
            <Link className={`nav-link`} rel="noreferrer" to={"/"}>
              <span className="footerLink">Sell</span>
            </Link>
            <span className="footerLink">Buy</span>
            <span className="footerLink green-text">Advertise</span>
            <span className="footerLink">Our Agent</span>
          </div> */}
          <div className="footer-col footer-separate-third-container">
            <h5 className="heading-clr">Support</h5>
            <Link to={"/faqs"} className={`nav-link`} rel="noreferrer">
              <span className="footerLink">FAQs</span>
            </Link>
            <Link to={"/pricing"} className={`nav-link`} rel="noreferrer">
              <span className="footerLink">Pricing</span>
            </Link>
            <Link
              to={"/terms-and-conditions"}
              className={`nav-link`}
              rel="noreferrer"
            >
              <span className="footerLink">Terms and conditions</span>
            </Link>
            <Link
              to={"/cancellation-and-refund-policy"}
              className={`nav-link`}
              rel="noreferrer"
            >
              <span className="footerLink mt-2">Refund Policy</span>
            </Link>

            <Link
              to={"/privacy-policy"}
              className={`nav-link`}
              rel="noreferrer"
            >
              <span className="footerLink">Privacy Policy</span>
            </Link>
            <Link to={"/remove-data"} className={`nav-link`} rel="noreferrer">
              <span className="footerLink">Remove Data</span>
            </Link>

            <span className="footerLink"></span>
          </div>
          <div className="footer-col footer-separate-forth-container text-start">
            <h5 className="heading-clr">Contact Us</h5>
            {/* <div className="email-icon-text">
              <img className="footer-icon" alt="Vector" src={email} />
              <span className="footerLink">+923001778863 </span>
            </div> */}
            <div className="email-icon-text">
              <img className="footer-icon" alt="Vector" src={phone} />
              <span className="footerLink">Invest@iride.pk</span>
            </div>
            <div className=" d-flex mt-2">
              <img
                className="footer-icon location-con"
                alt="Vector"
                src={location}
              />
              <p className="footerLink ms-1">
                Plaza No: 15/2 CCA-2, <br /> Block-D Phase 5, D.H.A <br />{" "}
                Lahore. Pakistan
              </p>
            </div>
            <span className="footerLink"></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
