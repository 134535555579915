import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { scrollToTop } from "../App";

const Pricing = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="container">
      <div className="text-center mb-5">
        <h1 className="text-center bold mt-4 mb-5 center-privacy">
          <b>Pricing</b>
        </h1>
        <p>
          Our pricing is dynamic and depends on several factors, including
          vehicle type, rental period, and location. To get an exact quote
          tailored to your specific needs, we recommend using our online booking
          tool. For a general understanding of our pricing structure, please
          refer to the pricing table below. Please note that these rates are
          estimates and actual prices may vary.
        </p>
      </div>

      <table className="table table-dark table-striped mt-5 rounded-3 table-css">
        <thead>
          <tr>
            <th scope="col">VEHICLE TYPE</th>
            <th scope="col">PRICE STARTING FROM (PER DAY)</th>
          </tr>
        </thead>
        <tbody className="table-text">
          <tr>
            <td>MOTORCYCLE</td>
            <td>Rs1,000</td>
          </tr>
          <tr>
            <td>BASIC CAR</td>
            <td>Rs4,000</td>
          </tr>
          <tr>
            <td>MID SIZE</td>
            <td>Rs6,000</td>
          </tr>
          <tr>
            <td>LUXURY CAR</td>
            <td>Rs11,000</td>
          </tr>
          <tr>
            <td>SMALL SUV</td>
            <td>Rs14,000</td>
          </tr>
          <tr>
            <td>MID SIZE SUV</td>
            <td>Rs17,000</td>
          </tr>
          <tr>
            <td>LUXURY SUV</td>
            <td>Rs25,000</td>
          </tr>
          <tr>
            <td>PREMIUM CAR</td>
            <td>Rs50,000</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Pricing;
