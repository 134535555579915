import React, { useEffect } from "react";
import { scrollToTop } from "../App";
import "./Landing/Landing.css";
const PrivacyPolicy = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <div className="container text-start mt-5 mx-auto d-flex flex-column gap-5 ">
        <section className="d-flex flex-column gap-2 privacy-policy-size">
          <h1 className="text-center bold mt-4 mb-5 center-privacy">
            <b>Privacy Policy</b>
          </h1>
          <p>
            Please read these terms and conditions carefully before using our
            services. By accessing or using our services, guests agree to be
            bound by these codes.
          </p>

          <p className="mt-2">
            Our approach to personal information involves three key categories:
          </p>
          <h2>1.INTRODUCTION</h2>
          <p>
            Welcome to iRide, an online car-sharing platform connecting vehicle
            owners with travellers and locals in Pakistan. By using our
            services, you agree to comply with these terms and conditions
            ("Terms").
          </p>
          <h2>2.ELIGIBILITY</h2>
          <ul>
            <li>
              You must be at least 21 years old and possess a valid driver's
              license to use our services.
            </li>
            <li>
              Vehicle owners must ensure their vehicles meet all legal and
              safety requirements.
            </li>
          </ul>
          <h2>3.USER ACCOUNTS</h2>
          <ul>
            <li>
              To use our services, you must create an account and provide
              accurate information.
            </li>
            <li>
              You are responsible for maintaining the confidentiality of your
              account credentials.
            </li>
          </ul>
          <h2>4.BOOKING AND PAYMENT</h2>
          <ul>
            <li>
              All fees and costs will be itemized at checkout for guests. You
              can review these amounts before submitting a trip request.
            </li>
            <li>Vehicle owners can view their earnings on their dashboard.</li>
            <li>
              By providing a payment method, you authorize us to store and use
              it for any charges you incur, including trip costs and related
              fees.
            </li>
          </ul>
          <h2>5.CONTENT AND CONDUCT</h2>
          <ul>
            <li>
              You may post content such as vehicle photos, reviews, and
              descriptions. By doing so, you grant us a non-exclusive,
              worldwide, royalty-free license to use this content for our
              services.
            </li>
            <li>
              You agree not to post any content that is illegal, offensive, or
              infringes on the rights of others.
            </li>
          </ul>
          <h2>6.INSURANCE AND LIABILITY</h2>
          <ul>
            <li>
              Vehicle owners must ensure their vehicles are insured as per
              Pakistani laws.
            </li>
            <li>
              Users/guests are responsible for any damage or fines incurred
              during the rental period.
            </li>
            <li>
              Indemnity: iRide will not be responsible for any disputes aroused
              between the consigned parties nor subjected for any compensations
              neither guarantor against the damages caused in accidents (major /
              minor) or other wear and tear matters. iRide is not responsible
              for the damages or repairs, we may only assist in facilitating
              communication between the parties if needed.
            </li>
          </ul>
          <h2>7.CANCELLATIONS AND REFUNDS</h2>
          <p>
            Guests may cancel their trip through our site or mobile app, and the
            cancellation is effective immediately. Whether the guest receives a
            full refund, a partial refund, or no refund depends on the
            circumstances. The total amount refunded will depend on when the
            guest cancels the trip, the length of the trip, and the trip type.
            The cancellation period is based on the vehicle’s time zone.
          </p>
          <h2>Cancellation period for guest perspective:</h2>
          <table class="table table-dark table-bordered">
            <thead>
              <tr className="text-center">
                <th scope="col">BOOKING TIME</th>
                <th scope="col">Refund / Pay</th>
              </tr>
            </thead>
            <tbody className="font-22">
              <tr>
                <th scope="row">24 hours or more before trip start</th>
                <td>Full Refund</td>
              </tr>
              <tr>
                <th scope="row">Less than 24 hours before trip start</th>
                <td>90 Refund and 10% will be charged by iRide</td>
              </tr>
              <tr>
                <th scope="row">Less than 02 Hour before trip start</th>
                <td>No Refund</td>
              </tr>
            </tbody>
          </table>
          <p>
            <strong>CANCELLATION PERIOD FOR iRide PERSPECTIVE</strong>
          </p>
          <ul>
            <li>If guest becomes unresponsive.</li>
            <li>If host becomes unresponsive.</li>
          </ul>
          <h2>TRIP MODIFICATIONS</h2>
          <p>
            If a guest requests a trip modification and the host accepts, that
            modification does not reset the free cancellation period for the
            trip. It remains tied to the original booking time.
          </p>
          <h2>EARLY RETURNS</h2>
          <p>
            There are no credits/refunds issued for early returns except when
            the guest has submitted a trip modification request to shorten their
            trip and the host has accepted through the iRide website or app, as
            defined in the iRide Terms and Conditions.
          </p>
          <h2>TRIPS CANCELED BY iRide</h2>
          <p>
            In some cases, the iRide trust and safety team will cancel a guest’s
            booked trip. If that were to happen, iRide will contact the guest
            and host and issue the guest a full refund. In these instances,
            Customer Support is available to help guests rebook 24/7.
          </p>
          <h2>8.MODIFICATIONS TO THE SERVICES</h2>
          <ul>
            <li>
              iRide reserves the right to modify or discontinue our services at
              any time, with or without notice.
            </li>
          </ul>
          <h2>9.ACCIDENT POLICY</h2>
          <h3>a. Immediate Actions</h3>
          <ul>
            <li>
              Ensure Safety: Prioritize safety for all parties involved. Contact
              emergency services immediately if there are any injuries (Rescue
              1122).
            </li>
            <li>
              Move to Safety: If it’s safe to do so, move the vehicles out of
              traffic to a secure location.
            </li>
            <li>
              Turn on Hazard Lights: Use hazard lights to alert other drivers
              about the accident.
            </li>
          </ul>
          <h3>b. Report the Accident</h3>
          <ul>
            <li>
              Contact Us: Inform iRide about the accident as soon as possible
              via our emergency hotline at iridefinance@mycipherdev.com.
            </li>
            <li>
              Police Report: Report the accident to the local police and obtain
              a police report. This is mandatory for indent reporting and
              processing insurance claims (if any).
            </li>
          </ul>
          <h3>c. Documentation</h3>
          <ul>
            <li>
              Exchange Information: Exchange names, contact details, and
              insurance information with all involved parties.
            </li>
            <li>
              Vehicle Details: Record the make, model, colour, and license plate
              numbers of all vehicles involved.
            </li>
            <li>
              Photographs: Take clear photos of the damage to all vehicles, the
              accident scene, and any relevant road conditions or signage. These
              pictures must be uploaded into the iRide Application.
            </li>
            <li>Witnesses: Collect contact information from any witnesses.</li>
          </ul>
          <h3>d. Insurance and Claims</h3>
          <ul>
            <li>
              Insurance Coverage: Ensure the vehicle has valid insurance
              coverage as per Pakistani law. Understand the insurance policy
              provided by the vehicle owner.
            </li>
            <li>
              Submit a Claim: File a claim with iRide by submitting the police
              report, photos, and any other relevant documents to the iRide
              concerned department.
            </li>
            <li>
              Cooperation: Cooperate fully with iRide, insurance providers, and
              law enforcement during the investigation and claims process.
            </li>
            <li>
              Engagement: iRide must not be an integral part or involved in any
              legal responsibilities, including accident, insurance claim, Local
              police reporting. This must be handled by the host and guest
              solely.
            </li>
            <li>
              Pledge: Both the guest and the host must document the damage
              thoroughly, including photographs and a detailed description of
              the incident. The host should obtain repair estimates from open
              market repair centres and share these with the guest. The guest
              and host must mutually agree on the repair costs and payment
              method. All arrangements for payment should be documented in
              writing. The agreed negotiations must be uploaded by the host and
              guest through their respective user accounts to iRide for record
              purposes.
            </li>
            <li>
              Indemnity: iRide will not be responsible for any disputes aroused
              between the consigned parties nor subjected to any compensations
              neither guarantor against the damages caused in accidents
              (major/minor) or other wear and tear matters. iRide is not
              responsible for the damages or repairs, we may only assist in
              facilitating communication between the parties if needed.
            </li>
          </ul>
          <h3>e. Liability</h3>
          <ul>
            <li>
              Driver Responsibility: The driver at the time of the accident is
              responsible for any damages, fines, or penalties incurred.
            </li>
            <li>
              Owner Responsibility: Vehicle owners must ensure their vehicles
              are safe, roadworthy, and legally insured. Keep insurance coverage
              up to date.
            </li>
            <li>
              Deductibles: The driver is responsible for any applicable
              deductibles under the insurance policy.
            </li>
            <li>
              Damage Fees: If damages exceed insurance coverage, the driver may
              be responsible for additional fees.
            </li>
            <li>
              Indemnity: iRide will not be responsible for any disputes aroused
              between the consigned parties nor subjected for any compensations
              neither guarantor against the damages caused in accidents
              (major/minor) or other wear and tear matters. iRide is not
              responsible for the damages or repairs, we may only assist in
              facilitating communication between the parties if needed.
            </li>
          </ul>
          <h3>f. Repairs</h3>
          <ul>
            <li>
              iRide Pakistan is not responsible for any damages resulting from
              accidents. The responsibility for handling and settling the costs
              of repairs lies solely with the guest (renter) and the host
              (vehicle owner).
            </li>
            <li>
              Guest Responsibility: The guest is responsible for covering the
              cost of repairs for any damage caused to the vehicle during the
              rental period, as per the rental agreement and local laws.
            </li>
            <li>
              Host Responsibility: The host must ensure that any repairs made to
              the vehicle meet the safety and quality standards required for
              future rentals.
            </li>
            <li>
              Indemnity: iRide will not be responsible for any disputes aroused
              between the consigned parties nor subjected to any compensations
              neither guarantor against the damages caused in accidents
              (major/minor) or other wear and tear matters. iRide is not
              responsible for the damages or repairs, we may only assist in
              facilitating communication between the parties if needed.
            </li>
          </ul>
          <h3>g. Trip Interruption</h3>
          <ul>
            <li>
              If the vehicle is deemed non-moveable and cannot continue the
              trip, the trip will be officially terminated at the time of the
              accident, vehicle out of order, seized by authorities, etc.
            </li>
            <li>
              Both parties, the guest and the host must document the incident
              and vehicle status thoroughly, including photographs and detailed
              descriptions.
            </li>
            <li>
              In case of unusual circumstances, the guests will receive a refund
              for the unused portion of the rental period once the aroused issue
              has become settled between the consigned parties (host and guest).
              The refund amount will be calculated based on the remaining rental
              time from the point of trip termination and credited in the
              guest's respective bank account.
            </li>
            <li>
              Indemnity: iRide will not be responsible for any disputes aroused
              between the consigned parties nor subjected to any compensations
              neither guarantor against the damages caused in accidents
              (major/minor) or other wear and tear matters. iRide is not
              responsible for the damages or repairs, we may only assist in
              facilitating communication between the parties if needed.
            </li>
          </ul>
          <h3>h. Policy Updates</h3>
          <ul>
            <li>
              Modifications: iRide reserves the right to update or modify this
              policy at any time. Changes will be communicated through our
              platform and will take effect immediately.
            </li>
          </ul>
          <h2>10. VEHICLE DAMAGE CLAIMS</h2>
          <p>
            <strong>
              Financial Responsibility for Damage to A Host’s Vehicle:
            </strong>
            You’re responsible for any eligible damage that occurs during a
            trip, whether or not you’re at fault or caused the damage. You have
            to pay out if your host’s vehicle is damaged during your trip.
          </p>
          <p>
            The amount you’d owe for physical damage to a host’s vehicle is
            determined by three factors:
          </p>
          <ul>
            <li>The amount of eligible damage, including costs and fees</li>
            <li>
              The amount that your personal auto insurance pays for those
              damages (if applicable)
            </li>
            <li>
              The assessment made by the third-party repair and maintenance
              service providers from the open market as the host will suggest to
              the guest.
            </li>
          </ul>
          <p>
            You’re responsible for eligible damage costs. You agree to take
            financial responsibility for damage to and accidents involving your
            host’s vehicle if and when the vehicle possession has been
            transferred to you for the trip. Once you have picked up the car
            from that point till return, the guest will be fully responsible for
            accidents, fire damage, theft, and any harm to the host vehicle.
          </p>
          <p>
            <strong>Indemnity:</strong> iRide will not be responsible for any
            disputes aroused between the consigned parties nor subjected for any
            compensations neither guarantor against the damages caused in
            accidents (major/minor) or other wear and tear matters. iRide is not
            responsible for the damages or repairs, we may only assist in
            facilitating communication between the parties if needed.
          </p>
          <h2>11.DISPUTE RESOLUTION</h2>
          <ul>
            <li>
              Any disputes arising from these terms will be resolved in
              accordance with Pakistani laws.
            </li>
            <li>
              In case of any disputes between the guest and the host regarding
              the repairs or payment, iRide encourages both parties to engage in
              good faith negotiations to reach an amicable solution.
            </li>
            <li>
              You agree to resolve any disputes through binding arbitration
              rather than court proceedings as further apprehended in the terms
              and conditions.
            </li>
            <li>
              iRide will not be responsible for any disputes aroused between the
              consigned parties nor subjected for any compensations neither
              guarantor against the damages caused in accidents (major/minor) or
              other wear and tear matters. iRide is not responsible for the
              damages or repairs, we may only assist in facilitating
              communication between the parties if needed.
            </li>
          </ul>
          <h2>12.PRIVACY</h2>
          <p>
            Your use of our services is also governed by our Privacy Policy,
            which outlines how we collect, use, and protect your personal
            information.
          </p>
          <h2>13.ACCOUNT CANCELLATION / TERMINATION</h2>

          <table className="table table-dark table-bordered">
            <thead>
              <tr>
                <th scope="col">Host Account Cancellation</th>
                <th scope="col">Guest Account Cancellation</th>
              </tr>
            </thead>
            <tbody className="font-22">
              <tr>
                <td>
                  Violation of Terms and Conditions: Any breach of the terms and
                  conditions agreed upon during registration.
                </td>
                <td>
                  Violation of Terms and Conditions: Any breach of the terms and
                  conditions agreed upon during registration.
                </td>
              </tr>
              <tr>
                <td>
                  Fraudulent Activity: Engaging in fraudulent activities, such
                  as misrepresentation of vehicle details, false claims, or
                  providing fraudulent documentation.
                </td>
                <td>
                  Fraudulent Activity: Providing false information, using stolen
                  credit cards, or engaging in any other form of frauds and
                  suspicious acts.
                </td>
              </tr>
              <tr>
                <td>
                  Illegal Use of Vehicle: Using the vehicle for illegal
                  activities or allowing it to be used for illegal purposes.
                </td>
                <td>
                  Illegal Activities: Using rented vehicles for illegal
                  activities or transporting illegal goods, ride sharing
                  services, etc.
                </td>
              </tr>
              <tr>
                <td>
                  Repeated Complaints: Receiving multiple valid complaints from
                  guests regarding the condition or safety of the vehicle and
                  other material affairs.
                </td>
                <td>
                  Repeated Complaints: Receiving multiple valid complaints from
                  hosts regarding the misuse or abuse of the vehicle.
                </td>
              </tr>
              <tr>
                <td>
                  Non-Compliance with Local Laws: Failing to comply with local
                  vehicle registration and safety regulations.
                </td>
                <td>
                  Damage to Vehicles: Repeatedly causing damage to rented
                  vehicles or failing to report damages accurately.
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  Vehicle Safety Issues: Renting out vehicles that are not
                  roadworthy or fail to meet safety standards.
                </td>
                <td>
                  Non-Payment: Failing to pay for rentals or any other fees
                  incurred during the use of the service.
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  Negative Feedback and Complaints: Continuous negative reviews
                  or multiple complaints from different users. While occasional
                  issues are understandable, a pattern of negative feedback can
                  indicate persistent problems with a host’s conduct or service
                  quality.
                </td>
                <td>
                  Driving Violations: Engaging in reckless driving or other
                  serious driving offenses while using a rented vehicle.
                </td>
              </tr>
            </tbody>
          </table>

          <h2>14.CONTACT INFORMATION</h2>
          <p>
            For any questions or concerns, please contact us at 03001778863.
          </p>
          <p>
            By using iRide you acknowledge that you have read, understood, and
            agreed to these terms and conditions, policies and codes.
          </p>
        </section>
      </div>
    </>
  );
};

export default PrivacyPolicy;
