import React, { useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { scrollToTop } from "../App";

const HostAgreement = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <div className="container">
      <div className="text-start mb-5 p-3 host-padding">
        <h1 className="text-center bold mt-4 mb-5 center-privacy">
          <b>Host Agreement</b>
        </h1>
        <p>
          <strong>Introduction:</strong> This Host Agreement outlines the terms
          and conditions governing the relationship between iRide, the "Cipher
          Developments Private Limited Lahore", ‘the Company’ and the Host,
          which in this case is the Vehicle Owner, who owns or manages a
          property listed on the Company's car rental platform. By agreeing to
          these terms, you are entering into a legally binding agreement with
          the Company.
        </p>
        <h2 className="mt-5">
          <b> Host Responsibilities</b>
        </h2>
        <p>
          <strong>1. Vehicle Information:</strong> You agree to provide accurate
          and up-to-date information about your Vehicle, including its location,
          amenities, availability, and pricing.
        </p>
        <p>
          <strong>2. Vehicle Maintenance:</strong> You are responsible for
          maintaining your property in a clean and safe condition, ensuring that
          it meets all applicable health and safety standards.
        </p>
        <p>
          <strong>3. Guest Interaction:</strong> You will handle all guest
          interactions, including pickup, drop off, and responding to inquiries.
        </p>
        <p>
          <strong>4. Pricing:</strong> You set the rental rates for your
          property, subject to the Company's approval.
        </p>
        <p>
          <strong>5. Taxes and Fees:</strong> You are responsible for collecting
          and remitting any applicable taxes and fees related to your property
          rentals.
        </p>
        <h2 className="mt-5">
          {" "}
          <b> Company Responsibilities</b>
        </h2>
        <p>
          <strong>1. Platform Access:</strong> iRide will provide you with
          access to its car rental platform to list your property and manage
          your bookings.
        </p>
        <p>
          <strong>2. Marketing and Promotion:</strong> iRide will promote your
          property through its marketing channels.
        </p>
        <p>
          <strong>3. Payment Processing:</strong> iRide will process payments
          from guests and remit the rental amount to you, minus any applicable
          fees.
        </p>
        <p>
          <strong>4. Customer Support:</strong> iRide will provide customer
          support to guests and address any issues related to your property.
        </p>
        <h4>Indemnification</h4>
        <p>
          You agree to indemnify and hold the Company harmless from any claims,
          losses, damages, or liabilities arising from your breach of this
          agreement or your actions related to your property.
        </p>
        <h4>Governing Law</h4>
        <p>
          This agreement shall be governed by and construed in accordance with
          the laws of the Islamic Republic of Pakistan.
        </p>
      </div>
    </div>
  );
};

export default HostAgreement;
