import React from "react";
import "./Header.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.png";
const Header = () => {
  return (
    <Navbar collapseOnSelect expand="lg" className="custom-head-color">
      <Container style={{"text-align": "left"}}>
        <Link to={"/"}>
          <Navbar.Brand href="#home" className="semi-bold i-ride">
          <img src={logo} className="logo" alt="iride"/>
            {/* i <span className="heading-clr semi-bold ride-new">Ride</span> */}
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto custom-header-class">
            {/* <Nav.Link href="#rent">Rent</Nav.Link>
            <Nav.Link href="#share">Share</Nav.Link>
            <Nav.Link href="#ride">Ride</Nav.Link>
            <Nav.Link href="#contact-us">
              {" "}
              <span className="heading-contact">Contact Us</span>
            </Nav.Link> */}
          </Nav>
          <Nav>
            {/* <Nav.Link href="#sign-up">Sign up</Nav.Link>
            <Button className="login">Login</Button> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
