import React, { useEffect, useState } from "react";
import "./Landing.css";
// import blueBg from "../../assets/images/bluebg.svg";
// import blueBg from "../../assets/images/newbgBlue.svg";
import blueBg from "../../assets/images/newbgBlue.svg";
import car from "../../assets/images/scene2.png";
import blueBgTwo from "../../assets/images/blue-bg-second.svg";
import Honda from "../../assets/images/HondaCivic.png";
import moneyAi from "../../assets/images/money-ai.png";
// import v8 from "../../assets/images/v8.svg";
import v8 from "../../assets/images/V8-New.svg";
import toyotaAi from "../../assets/images/toyota-ai.png";
import landingImage from "../../assets/images/landing-image.png";
import carIcon from "../../assets/images/car-icon.svg";
import greenline from "../../assets/images/green-line.svg";
import arrowLeft from "../../assets/images/arrow-left.svg";
import arrowRight from "../../assets/images/arrow-right.svg";
import soloCar from "../../assets/images/solo-car.svg";
import onlyCar from "../../assets/images/only-car.svg";
import interior from "../../assets/images/interior.svg";
import bigArrow from "../../assets/images/big-arrow-right.svg";
import google from "../../assets/images/googleplay.svg";
import appStore from "../../assets/images/applestore.svg";
import interiorWwide from "../../assets/images/interior-wide.svg";
import blueBgThree from "../../assets/images/blue-bg-three.svg";
import merc from "../../assets/images/merc.svg";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { scrollToTop } from "../../App";
import { useInView } from "react-intersection-observer";
import { useMediaQuery } from "react-responsive";

const SectionWithAnimation = ({ children }) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "-50px 1050px",
  });

  return (
    <div ref={ref} className={`animated-section ${inView ? "in-view" : ""}`}>
      {children}
    </div>
  );
};

const Landing = () => {
  const isMobile = useMediaQuery({ maxWidth: 700 });

  useEffect(() => {
    scrollToTop();
  }, []);

  const [hoverImage, setHoverImage] = useState(null);
  const images = [
    { src: "./Images/brand1.svg", alt: "honda-1" },
    { src: "./Images/brand2.svg", alt: "audi-1" },
    { src: "./Images/brand4.svg", alt: "audi-1" },
    { src: "./Images/brand5.svg", alt: "toyota-1" },
  ];
  const imagesDestination = [
    { src: "./Images/karachi.svg", alt: "karachi-1" },
    { src: "./Images/isb.svg", alt: "isb-1" },
    { src: "./Images/peshawar.svg", alt: "peshawar-1" },
    { src: "./Images/lahore.svg", alt: "lahore-1" },
    { src: "./Images/faislabad.svg", alt: "faislabad-1" },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 3000,
    slidesToShow: isMobile ? 3 : 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: true,
    Responsive: true,
  };

  return (
    <>
      <div className="container" style={{ overflow: "hidden" }}>
        {/* first section start*/}
        <div className="d-flex justify-content-between main-first-container">
          <div className="d-flex flex-column">
            <div className="text-start flex-column d-flex rent-your-car">
              <span className="heading-second"><span className="heading-second" style={{"color": "#0074ff"}}>Ride</span> Your Way</span>
              <span className="heading-second">Anytime, Anywhere</span>
            </div>
            <span className="green-line">|</span>
            <span className="desc text-start" style={{"color": "#c4e332", "fontSize": "20px"}}>
              Pakistan's 1<sup>st</sup> Peer to Peer car sharing platform
            </span>
            <span className="text-start desc">
             {/* with peer to peer car rental service.{" "} */}
            </span>
            <div className="d-flex gap-2 mt-5 appstore-button-main">
              {/* <a
                href="https://play.google.com/store/apps/app-id"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={google} alt="Google Play Store" />
              </a> */}
              {/* <a
                href="https://itunes.apple.com/app/app-id"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={appStore} alt="Apple App Store" />
              </a> */}
            </div>
            {/* <input
              className="search-input"
              placeholder="City, airport, address or hotel"
            ></input>
            <button className="search-button">
              {" "}
              <Search color="#C4E332" className="me-2" />{" "}
              <span className="semi-bold">Search</span>
            </button> */}
          </div>
          <div className="" id="car-bg">
            <img src={blueBg} alt="" className="first-container-second-sec" style={{"visibility": "hidden"}} />
            <img src={landingImage} className="landing-image" alt="car"></img>
          </div>
        </div>
        {/* first section end*/}

        {/* second section start*/}
        <SectionWithAnimation>
          <div className="" style={{visibility: "hidden"}}>
            <h3 className="text-center">
              Services <span className="brands-heading">We Offer</span>
            </h3>
            <div className="slider-container-below">
              <Slider {...settings}>
                {images.map((image, index) => (
                  <div key={index}>
                    <img
                      className="slider-images"
                      src={process.env.PUBLIC_URL + image.src}
                      alt={image.alt}
                      onMouseOver={() => setHoverImage(index)}
                      onMouseLeave={() => setHoverImage(null)}
                    />
                    {hoverImage === index && (
                      <img className="hover-line" src={greenline} alt="" />
                    )}
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </SectionWithAnimation>
        {/* second section end */}

        {/* Third section start */}
        <SectionWithAnimation>
          <div className="third-section-main d-flex justify-content-between main-first-container" style={{"marginBottom": "10rem"}}>
            <div className="third-section-first" id="second-car-and-bg">
              <img src={blueBgTwo} alt="blue-bg-two" className="blue-bg-two" style={{visibility: "hidden"}} />
              <img className="toyota-ai" src={toyotaAi} alt="" />
            </div>
            <div className="third-section-second d-flex flex-column text-start">
              {/* <div className="d-flex" id="arrows">
                <img src={arrowLeft} className="left-icon" alt="car-icon" />
                <img src={arrowRight} className="right-icon" alt="car-icon" />
              </div> */}
              {/* <img src={carIcon} className="car-icon" alt="car-icon" /> */}
              <span className="to-upgrade semi-bold">
                {" "}Why Use iRide? {" "}
              </span>
              <br/>
              <span className="">Diverse Selection</span>
              <span className="">Seamless Booking</span>
              <span className="">Affordable Rates</span>
              <span className="">Community Support</span>
              <p className="sec-car-desc" style={{"visibility": "hidden"}}>
              Choose from a wide range of vehicles, from comp.
              </p>
              {/* <button className="browse-cars">Browse Cars</button> */}
            </div>
          </div>
        </SectionWithAnimation>
        {/* Third section end */}
        <SectionWithAnimation>
          <div className="third-section-main d-flex justify-content-between main-first-container"  style={{"marginBottom": "10rem"}}>
            
            <div className="third-section-second d-flex flex-column text-start">
              {/* <div className="d-flex" id="arrows">
                <img src={arrowLeft} className="left-icon" alt="car-icon" />
                <img src={arrowRight} className="right-icon" alt="car-icon" />
              </div> */}
              {/* <img src={carIcon} className="car-icon" alt="car-icon" /> */}
              <span className="to-upgrade semi-bold" style={{"marginTop": "5rem"}}>
                {" "}Need a Side Income ? {" "}
              </span>
              <br/>
              <span className="">Turn Your Car into a &nbsp;Profit Machine</span>
              <span style={{"paddingLeft":"20px","margin-top":"10px"}}>1.  &nbsp;&nbsp;List Your Vehicle</span>
              <span style={{"paddingLeft":"20px"}}>2.  &nbsp;Accept Reservations</span>
              <span style={{"paddingLeft":"20px"}}>3.  &nbsp;Generate Income</span>
              <p className="sec-car-desc" style={{"visibility": "hidden"}}>
              Choose from a wide range of vehicles, from comp.
              </p>
              {/* <button className="browse-cars">Browse Cars</button> */}
            </div>
            <div className="third-section-first" id="second-car-and-bg">
              <img src={blueBgTwo} alt="blue-bg-two" className="blue-bg-two" style={{visibility: "hidden"}} />
              <img className="honda-car" src={moneyAi} alt="" />
            </div>
          </div>
        </SectionWithAnimation>
        {/* forth section start */}
        {/* <SectionWithAnimation>
          <div className="container forth-main-c">
            <h3 className="text-center semi-bold">
              Our <span className="brands-heading">Top Pick</span> for Today!
            </h3>
            <div className="container-three-port mt-5 mb-5 container-forth">
              <div className="d-flex gap-2">
                <img src={soloCar} alt="solocar" className="solo-car" />
                <div className="d-flex flex-column gap-2 ">
                  <div className="d-flex gap-2">
                    <img src={interior} className="interior" alt="interior" />
                    <img src={onlyCar} className="onlycar" alt="car" />
                  </div>
                  <img
                    src={interiorWwide}
                    className="interirowide"
                    alt="wide"
                  />
                </div>
              </div>
            </div>
          </div>
        </SectionWithAnimation> */}
        {/* forth section end */}

        {/* fifth section start */}
        {/* <SectionWithAnimation>
          <div className="fifth-section">
            <div className="second-section-landing mt-5 ">
              <h3 className="text-center">
                Browse by <span className="brands-heading">Destination</span>
              </h3>
              <div className="slider-container-below">
                <Slider {...settings}>
                  {imagesDestination.map((image, index) => (
                    <div key={index}>
                      <img
                        className="slider-images"
                        src={process.env.PUBLIC_URL + image.src}
                        alt={image.alt}
                        onMouseOver={() => setHoverImage(index)}
                        onMouseLeave={() => setHoverImage(null)}
                      />
                      {hoverImage === index && (
                        <img className="hover-line" src={greenline} alt="" />
                      )}
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </SectionWithAnimation> */}
        {/* fifth section end */}

        {/* sixth section start */}
        <SectionWithAnimation>
          <div className="sixth-section mb-5">
            <div className="third-section-main d-flex main-first-container">
              <div className="third-section-first" id="second-car-and-bg">
                <img
                  src={blueBgThree}
                  alt="blue-bg-three"
                  className="blue-bg-four"
                  style={{"visibility": "hidden"}}
                />
               <img className="honda-car" src={Honda} alt="" />
              </div>
              <div className="third-section-second d-flex flex-column text-start" style={{"marginLeft":"6rem"}}>
                {/* <div className="d-flex" id="arrows">
                  <img src={arrowLeft} className="left-icon" alt="car-icon" />
                  <img src={arrowRight} className="right-icon" alt="car-icon" />
                </div> */}
                {/* <span className="book-a-car">
                  Book a <span className="to-upgrade semi-bold">Car</span>{" "}
                  <img className="ms-4 mb-1" src={bigArrow} alt="" />
                </span> */}
                <span className="to-upgrade semi-bold" style={{"marginTop": "5rem"}}>
                {" "} Book a Car {" "}
              </span>
                <p className="sec-car-desc">
                  Down the street or across the country, find the perfect <br />{" "}
                  vehicle for your next adventure.
                </p>
              </div>
            </div>
          </div>
        </SectionWithAnimation>
        {/* sixth section end */}
      </div>
      {/* <Footer /> */}
      {/* </header> */}
    </>
  );
};

export default Landing;
