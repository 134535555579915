import React, { useEffect } from "react";
import { scrollToTop } from "../App";

const RefundPolicy = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <div className="container mx-auto text-start d-flex flex-column gap-5 mt-5 privacy-policy-size">
        <h1 className="container text-center mx-auto">
          <b>CANCELLATION AND REFUND POLICY</b>
        </h1>
        <p>
          Guests may cancel their trip through our site or mobile app, and the
          cancellation is effective immediately. Whether the guest received a
          full refund, a partial refund, or receives no refund depends on the
          circumstances. The total amount refunded will depend on when the guest
          cancels the trip, the length of the trip, and the trip type. The
          cancellation period is based on the vehicle's time zone.
        </p>
        <h2>Cancellation period for guest perspective:</h2>
        <table class="table table-dark table-bordered">
          <thead>
            <tr className="text-center">
              <th scope="col">BOOKING TIME</th>
              <th scope="col">Refund / Pay</th>
            </tr>
          </thead>
          <tbody className="font-22">
            <tr>
              <th scope="row">24 hours or more before trip start</th>
              <td>Full Refund</td>
            </tr>
            <tr>
              <th scope="row">Less than 24 hours before trip start</th>
              <td>90 Refund and 10% will be charged by IRIDE</td>
            </tr>
            <tr>
              <th scope="row">Less than 02 Hour before trip start</th>
              <td>No Refund</td>
            </tr>
          </tbody>
        </table>
        <h2>CANCELLATION PERIOD FOR IRIDE PERSPECTIVE</h2>
        <p>1: If guest become unresponsive.</p>

        <p>2: If host becomes unresponsive.</p>
        <h2>TRIP MODIFICATIONS</h2>
        <p>
          If a guest requests a trip modification and the host accepts, that
          modification does not reset the free cancellation period for the trip.
          It remains tied to the original booking time.
        </p>
        <h2>EARLY RETURNS</h2>
        <p>
          There are no credits / refunds issued for early returns except when
          the guest has submitted a trip modification request to shorten their
          trip and the host has accepted through the IRIDE website or app, as
          defined in the IRIDE Terms and Conditions.
        </p>
        <h2>TRIPS CANCELED BY IRIDE</h2>
        <p>
          In some cases, IRIDE trust and safety team will cancel a guest’s
          booked trip. If that were to happen, IRIDE will contact the guest and
          host, and issue the guest a full refund. In these instances, Customer
          Support is available to help guests rebook 24/7.
        </p>
      </div>
    </>
  );
};

export default RefundPolicy;
