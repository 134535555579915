import React, { useEffect } from "react";

const Redirect = () => {
  useEffect(() => {
    console.log('i am here for redirect')
    let redirectUrl = "https://www.iridepk.com";

    const redirectTimer = setTimeout(() => {
      window.location.href = redirectUrl;
    }, 200);

    return () => clearTimeout(redirectTimer);
  }, []);

  return (
    <div className="redirect-container">
      <div className="loading-text">
        <div className="skeleton-text"></div>
        <div className="skeleton-text mt-3"></div>
        <div className="skeleton-text mt-3"></div>
        <div className="skeleton-text mt-3"></div>
        <div className="skeleton-text mt-3"></div>
        <div className="skeleton-text mt-3"></div>
        <div className="skeleton-text mt-3"></div>
        <div className="skeleton-text mt-3"></div>
      </div>
      <div className="car-container">
        <div className="car-skeleton">
          <div className="car-body"></div>
          <div className="car-wheel"></div>
          <div className="car-wheel"></div>
        </div>
      </div>
    </div>
  );
};

export default Redirect;
