import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { scrollToTop } from "../App";
import upArrow from "../assets/images/up-arrow.svg";
import downArrow from "../assets/images/down-arrow.svg";

const Faqs = () => {
  useEffect(() => {
    scrollToTop();
  }, []);

  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="container mt-5">
      <h2 className="text-center mb-4 text-light mt-3">
        <b>Frequently Asked Questions (FAQs)</b>
      </h2>
      <div
        className="accordion accordion-dark no-border mt-5"
        id="faqAccordion"
      >
        {faqData.map((item, index) => (
          <div className="accordion-item bg-dark p-3 " key={index}>
            <h2 className="accordion-header" id={`heading${index}`}>
              <button
                className="accordion-button collapsed bg-dark text-light fw-bolder  font-32"
                type="button"
                onClick={() => toggleAccordion(index)}
                data-bs-toggle="collapse"
                data-bs-target={`#collapse${index}`}
                aria-expanded="false"
                aria-controls={`collapse${index}`}
              >
                {item.question}
                <img
                  src={openIndex === index ? upArrow : downArrow}
                  alt="arrow"
                  className="ms-auto"
                />
              </button>
            </h2>
            <div
              id={`collapse${index}`}
              className="accordion-collapse collapse"
              aria-labelledby={`heading${index}`}
              data-bs-parent="#faqAccordion"
            >
              <div className="accordion-body bg-dark text-light text-start font-light">
                {item.answer}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const faqData = [
  {
    question: "1. How do I create an account?",
    answer:
      'To create an account, download the app from the App Store or Google Play, open the app, and click on "Sign Up." Follow the prompts to enter your details and create a password.',
  },
  {
    question: "2. How do I book a car?",
    answer:
      "After logging into your account, enter your pick-up location, rental dates, and car preferences. Browse through the available options, select the car you want, and follow the steps to complete your booking.",
  },
  {
    question: "3. What are the payment options?",
    answer:
      "We accept all major credit cards, debit cards, via secure bank link. Payment is required at the time of booking.",
  },
  {
    question: "4. Can I modify or cancel my booking?",
    answer:
      'Yes, you can modify or cancel your booking through the app. Go to "Modify Trips," select the reservation you want to change, and follow the prompts. Please note that cancellation fees may apply based on the timing of your cancellation. For this you can visit our refund policy available on our main website.',
  },
  {
    question: "5. What is the minimum age requirement to rent a car?",
    answer:
      "The minimum age requirement varies by location but is typically 21 years old. Drivers under 25 may incur a young driver surcharge.",
  },
  {
    question: "6. Do I need a credit card to rent a car?",
    answer:
      "Yes, a credit card or debit card is required to secure your rental. It will be used for the payment of the rental and to cover any potential additional charges.",
  },
  {
    question: "7. Is there a mileage limit?",
    answer:
      "Most rentals come with unlimited mileage. However, some vehicles or locations may have mileage restrictions. Please check the details during the booking process.",
  },
  {
    question: "8. What should I do if I have an accident or breakdown?",
    answer:
      "In the event of an accident or breakdown, contact immediately through the app or by calling the number provided in your rental agreement. Follow their instructions for next steps.",
  },
  {
    question: "9. Can I add an additional driver?",
    answer:
      "Yes, you can add an additional driver during the booking process. The additional driver must meet the same requirements as the primary driver.",
  },
  {
    question: "10. What documents do I need to pick up the car?",
    answer:
      "You will need a valid driver's license, the debit/credit card used for booking, and a second form of ID (such as a NIC or valid passport). International renters may need to provide an International Driving Permit (IDP).",
  },
  {
    question: "11. Are there any additional fees?",
    answer:
      "Additional fees may apply for optional services such as GPS, child seats, or insurance coverage. These will be clearly listed during the booking process.",
  },
  {
    question: "12. How do I extend my rental period?",
    answer:
      'To extend your rental period, go to "My Rides" in the app, select the reservation you want to extend, and follow the prompts. Extensions are subject to availability and additional charges.',
  },
  {
    question: "13. What is your fuel policy?",
    answer:
      'Our standard fuel policy is "full to full." This means you will receive the car with a full tank of fuel and must return it with a full tank to avoid additional charges.',
  },
  {
    question: "14. How can I contact customer support?",
    answer:
      'You can contact our customer support team through the app\'s "Help" section, via email, or by calling the customer service number provided on our website.',
  },
  {
    question: "15. What is your insurance policy?",
    answer:
      "Vehicle owners must ensure their vehicles are insured as per Pakistani laws.",
  },
];

export default Faqs;
