import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../App";
import axios from "axios";
import { Spinner } from "react-bootstrap";
const DeleteUserData = () => {
  const [email, setEmail] = useState("");
  const [isButtonClickable, setIsButtonClickable] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    scrollToTop();
  }, []);

  const handleClick = async () => {
    setIsButtonClickable(false);
    try {
      setSpinner(true);
      const apiUrl = `${process.env.REACT_APP_IRIDE_API_URL}/user`;
      let payload = {
        email: email,
      };
      const response = await axios.delete(apiUrl, payload);
      if (response?.status === 200) {
        setIsButtonClickable(true);
        Swal.fire("Submitted", "Your data has been deleted", "success").then(
          () => navigate("/")
        );
      } else {
        Swal.fire("Error!", `${response?.data?.msg}`, "error");
      }
      setSpinner(false);
    } catch (error) {
      setIsButtonClickable(true);

      setSpinner(false);
      Swal.fire(
        "Error!",
        `${error?.response?.data?.msg || error?.response?.data?.msg}`,
        "error"
      );
      console.error("Error:", error);
    }
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
    setIsButtonClickable(e.target.value !== "");
  };

  return (
    <>
      <div className="container">
        <h3 className="delete-text">
          {" "}
          Please Enter Your Email to delete your user data
        </h3>
        <div className="form-container">
          <form>
            <div className="mb-3 text-start mb-4">
              <label htmlFor="exampleInputEmail1" className="form-label mb-4">
                Email address
              </label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter Your Email Address"
                value={email}
                onChange={handleChange}
              />
              <div id="emailHelp" className="form-text text-white">
                <label className="email-text-t">
                  We'll never share your email with anyone else.
                </label>
              </div>
            </div>
            <div className="d-flex justify-content-center gap-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleClick}
                disabled={!isButtonClickable}
              >
                Submit
              </button>
              {spinner && <Spinner style={{ color: "#1877f2" }} />}
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default DeleteUserData;
