import React, { useEffect } from "react";
import "../modules/Landing/Landing.css";
import { scrollToTop } from "../App";
const TermsAndConditions = () => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <>
      <div className="container text-start mt-5  d-flex flex-column gap-5">
        <section className="d-flex terms-conditions flex-column justify-content-center  gap-2">
          <h1 className="text-center center  bold mt-4 mb-5 center-privacy">
            Terms and Conditions
          </h1>
          <p className="mt-2">
            Please read these terms and conditions carefully. By accessing or
            using the IRIDE platform, including our website and mobile
            application (collectively referred to as the "Services Terms &
            Conditions"), you agree to be bound by these terms and conditions.
            These terms include important provisions that affect your rights,
            remedies, and obligations.
          </p>

          <h1 className="mt-1">INTRODUCTION:</h1>
          <p>
            IRIDE operates an online car sharing service connecting vehicle
            owners with travellers and locals seeking to book those vehicles. By
            accessing or using the Services, including communicating with us or
            other IRIDE users, you agree to comply with these Terms of Service
            ("Terms"). A Car Sharing Agreement, accessible in the Services for
            any booked or previous trips, summarizes the terms of each
            reservation and serves as proof of reservation.
          </p>

          {/* <!-- Data Provided by You --> */}
          <h3 className="mt-3">ELIGIBILITY, REGISTRATION, VERIFICATION:</h3>
          <p>
            The Services are intended solely for guests who meet our eligibility
            requirements in the location where the vehicle is booked and hosts
            and guests who are 21 years of age or older meet the eligibility
            criteria. However, any use of the services by anyone that does not
            meet these eligibility requirements are expressly prohibited.
          </p>
          <p>
            To access certain features of the Services, you must sign up for an
            account with IRIDE. You can create an IRIDE Account. When you book a
            vehicle as a traveller or guest you provide us with certain
            additional information about yourself. Similarly, when you list a
            vehicle as a vehicle owner or host, you provide us with certain
            additional information about yourself and your vehicle(s) (if
            applicable). You must provide accurate, current, and complete
            information during the registration, booking, and/or listing
            process. You must keep your IRIDE Account up to date at all times.
            Based on information you provide, IRIDE may impose additional
            requirements for you to book a trip (e.g., providing a deposit,
            adding a second form of payment, or other requirements).
          </p>
          <p>
            IRIDE reserves the right to conduct screenings, checks, and
            processes to verify user identities, driving history, and vehicle
            details, using third-party services as needed. We may use discretion
            in permitting or refusing vehicle bookings or listings. While we
            make efforts to ensure vehicle safety, we do not endorse or
            guarantee the safety, roadworthiness, or legal status of vehicles
            beyond our policies.
          </p>
          <h1>FEES AND TAXES:</h1>
          <p>
            The fees we charge for using the Services and other cost structures
            will be itemized at checkout for guests. You can verify the amount
            for your trip at checkout before you submit your trip request. Hosts
            can view earnings on the Host Hub and learn more about earnings
            breakdown here. When you provide IRIDE a payment method, you
            authorize IRIDE to store your payment credential for future use in
            the event you owe IRIDE any money. You authorize IRIDE to use stored
            payment credentials for balances, including for Trip costs, host
            fees, and guest fees (e.g., late fees, security deposits, processing
            fees and claims costs, and related administrative fees). In some
            cases, our payment processors have Page 2 of 14 arrangements with
            card networks to automatically update stored payment credentials
            whenever you receive a new card (e.g., replacing an expired card or
            one that was reported lost or stolen) and we will rely on such
            updates to stored payment credentials for balances. Any use of
            referral travel credit is governed by the terms and conditions
            outlined here. The IRIDE is entitled to collect and deposit the
            provincial service tax from the end user to meet the statutory
            compliances for the permanent establishment in Pakistan.
          </p>
          <h1>COMMITMENTS:</h1>
          <p>
            You agree that you will always use your IRIDE Account and the
            Services in compliance with these Terms, applicable law, and any
            other policies and standards provided to you by IRIDE.
          </p>
          <h1>ACCOUNT ACTIVITY:</h1>
          <p>
            You are, and will be solely responsible for, all activity that
            occurs through your IRIDE Account. Keep your IRIDE Account
            information, including your password, secure. You agree that you
            will not disclose your password to any third party and that you will
            take sole responsibility for any activities or actions under your
            IRIDE Account, whether you have authorized such activities or
            actions. You will immediately notify IRIDE of any actual or
            suspected unauthorized use of your IRIDE Account. We are not
            responsible for your failure to comply with this clause, or for any
            delay in shutting down or protecting your IRIDE Account unless you
            have reported unauthorized access to us.
          </p>
          <h1>CONTENT:</h1>
          <p>
            You may post, upload, or transmit content through the Services, such
            as photographs, reviews, and feedback. By doing so, you grant IRIDE
            a worldwide, irrevocable, perpetual, non-exclusive license to use,
            copy, adapt, distribute, publicly display, and exploit such content
            for promoting or marketing the Services. IRIDE does not claim
            ownership of your content, and you retain any rights you have to use
            and exploit it.
          </p>
          <h1>COPYRIGHT PROTECTION:</h1>
          <p>
            We respond to notices of alleged copyright infringement and
            terminate IRIDE Accounts of repeat infringers according to the
            federal and provisional statutory compliances and similar laws. If
            you think a user is violating your copyright(s) and want to notify
            us, you can find information about submitting notices to IRIDE.
          </p>
          <h1>PROHIBITED ACTIVITIES:</h1>
          <p>
            In connection with your use of or access to the Services, you agree
            that you will not, nor advocate, encourage, request, or assist any
            third party to breach, violate, and/or circumvent any local, state,
            provincial/territorial, regional, or national law or other law or
            regulation, or any order of a court, including, without limitation,
            airport regulations and tax regulations, licensing or registration
            requirements, or third-party rights
          </p>
          <p>
            Infringe, reproduce, perform, display, distribute, reverse engineer,
            or prepare derivative works from content that belongs to or is
            licensed to IRIDE, or that comes from the Services and belongs to
            another IRIDE user or to a third party, including works covered by
            any copyrights, trademark, patent, or other Page 3 of 14
            intellectual property, privacy, publicity, moral, or contractual
            rights, except with prior express written permission of IRIDE
          </p>
          <p>
            Through unauthorized use of the Services and/or user content,
            Registering and/or using "IRIDE" or derivative terms in domain
            names, trade names, trademarks, or otherwise Registering and/or
            using domain names, trade names, trademarks, social media account
            names, or other means of identification that closely imitate or are
            confusingly similar to IRIDE domains, trademarks, taglines,
            promotional campaigns, or IRIDE and/or user content, False name,
            date of birth, driver’s license details, payment method, insurance,
            or other personal information, By registering for a IRIDE Account on
            behalf of an individual other than yourself or the company you
            represent, Impersonating any person or entity, or falsifying or
            otherwise misrepresenting yourself or your affiliation with any
            person or entity, Fail to pay fees, penalties, or other amounts owed
            to IRIDE or another user, Fail, as either a guest or host, to timely
            deliver, make available, or return any vehicle and optional Extras,
            unless you have a valid reason, Use the Services to find a host or
            guest, and then complete a transaction partially or wholly
            independent of the Services, Transfer your IRIDE Account and/or user
            ID to another party without our consent, Leave a vehicle unlocked or
            running with the keys inside, except where instructed to do so
            directly by IRIDE in certain limited circumstances, Harass, stalk,
            or defame any other IRIDE user or collect or store any personally
            identifiable information about any other user other than for
            purposes of transacting as a host or guest in accordance with these
            Terms, Use the Services to transmit, distribute, post, or submit any
            information concerning any other person or entity, including without
            limitation, photographs of others without their permission, personal
            contact information, payment method details, or account numbers,
            Treat anyone differently based on the way they look, who they love,
            what they believe, how they self-identify, where they are from, or
            when they were born. Discrimination of any kind is not tolerated in
            the IRIDE community, Sue or assert legal claims against IRIDE or a
            IRIDE user in any manner prohibited or waived by these Terms,
            Contact another IRIDE user for any purpose other than in relation to
            a booking, vehicle, listing, or the use of the Services by such
            user, Commercialize any content found on the Services or software
            associated with the Services, including reviews, Harvest or
            otherwise collect information about users without their and our
            consent, Recruit or otherwise solicit any user to join third-party
            services or websites that are competitive to IRIDE, without our
            prior written approval, Using the Services in connection with the
            distribution or posting of unsolicited commercial messages (e.g.,
            spam), Avoiding, bypassing, removing, deactivating, impairing,
            descrambling, or otherwise circumventing any technological measure
            implemented by IRIDE or any of our service providers or any other
            third party (including another user) to protect the Services,
            endeavouring to circumvent a suspension, termination, or closure of
            your IRIDE Account or the account of another IRIDE user, including,
            but not limited to, creating a new IRIDE Account or listing vehicles
            affiliated with or registered to a IRIDE Account holder that has
            been suspended, terminated, or closed
          </p>
          <h1>VIOLATIONS:</h1>
          <p>
            IRIDE has the right, but not the obligation, to investigate, pursue,
            and seek to prosecute, litigate, or refer to law enforcement,
            violations of the Agreement to the fullest extent permissible by the
            law. IRIDE reserves the right, at any time and without prior notice,
            in accordance with applicable law, to remove or disable access to
            any content that IRIDE, at its sole discretion, considers to be
            objectionable for any reason, in violation of these Terms, or
            otherwise harmful to the Services or our community. If we believe
            you are abusing IRIDE, our users, or any other person in any way or
            violating the letter or spirit of any of these Terms, we may, in our
            sole discretion and without limiting other remedies, limit, suspend,
            or terminate your IRIDE Account and access to the Services, remove
            hosted content, deny a Page 4 of 14 damage claim, remove or demote
            your listings, reduce or eliminate any discounts, and take technical
            and/or legal steps to prevent you from using the Services.
            Additionally, we reserve the right to refuse or terminate access to
            the Services to anyone for any reason at our discretion to the full
            extent permitted under applicable law.
          </p>
          <p>
            When an issue arises, we may consider the user’s performance history
            and the specific circumstances in applying our Policies. We may
            choose to be more lenient with policy enforcement in an effort to do
            the right thing, subject to our sole and absolute discretion.
          </p>
          <p>
            You agree that IRIDE may contact you by electronic means (e.g.,
            electronic mail; notifications via IRIDE messaging; app
            notification) in lieu of any requirement for mailed notices. You
            authorize IRIDE without further notice, to monitor or record
            telephone conversations or web chat interactions you have, or anyone
            acting on your behalf has, with IRIDE or its agents for quality
            control, training, or other purposes. You understand and agree that
            your communications with IRIDE may be overheard, monitored, or
            recorded. If you do not wish to have your call recorded, please
            contact us instead in writing through help.IRIDE.com. If you do not
            wish to have your chat activity recorded or monitored, please do not
            use the chat function on the Services. IRIDE is not an insurance
            company and does not insure hosts or guests
          </p>
          <h1>SPECIFIC TERMS FOR GUESTS:</h1>
          <p>
            The following sections also apply if you book a vehicle using the
            Services:
          </p>
          <h1>GUEST COMMITMENTS:</h1>
          <p>
            As a guest, you commit that you will be a legally licensed driver
            and provide proof to the host or via the Services of a current,
            valid driver’s license. You will treat the vehicle and any
            applicable Extras well and will take all reasonable measures to
            return the vehicle and any applicable Extras on time and in
            essentially the same condition as received. You will not allow
            anyone other than a person listed in the trip details as an Approved
            Driver to drive the vehicle you booked.
          </p>
          <p>
            For guests who are residents out of Lahore. There is no right of
            withdrawal in relation to a transaction you make on the Services as
            this right does not apply to the booking of transport or other
            services provided for at a specific date. Your only rights are
            described in the cancellation policy
          </p>

          <h1>
            GUEST FINANCIAL RESPONSIBILITY FOR PHYSICAL DAMAGE TO THE VEHICLE:
          </h1>
          <p>
            The guest that booked the trip (“primary guest”) is financially
            responsible for all physical damage to or theft of a booked vehicle
            that occurs during a trip, plus any additional costs and fees
            resulting from damage of any kind to the vehicle, regardless of who
            is found to be at fault. This responsibility applies whether the
            primary guest has their own auto insurance or not.
          </p>
          <p>
            Primary guests may be insured against damage to the booked vehicle
            under their own automobile policies. When you book a vehicle on
            IRIDE, you agree that if any damage occurs to the booked vehicle
            during the booked trip, you will work with IRIDE to make a claim for
            coverage under any policy of insurance that applies to the loss.
          </p>
          <p>
            The primary guest can limit the amount they are obligated to pay out
            of their own pocket in the event there is damage to the booked
            vehicle during the booked trip by choosing a protection plan on the
            Services. The limitation on the amount a primary guest may have to
            pay out of pocket included in any Page 5 of 14 protection plan only
            applies (1) if the primary guest and any Approved Driver abide by
            these Terms and (2) to physical damage that is not mechanical or
            interior damage.
          </p>
          <h2>Use of the Vehicle</h2>
          <p>
            You can only use the vehicle for personal use and not for commercial
            purposes (e.g., Uber, Lyft, delivering food).
          </p>
          <p>
            You must have a current, valid driver's license to access the
            vehicle.
          </p>
          <p>
            You are expected to operate the vehicle safely and in accordance
            with all applicable laws.
          </p>
          <p>You must wear seat belts and ensure all passengers do the same.</p>
          <p>You cannot leave the car unlocked or with the keys unsecured.</p>
          <p>
            Misusing the vehicle will make you financially responsible for any
            claims, loss, or damage.
          </p>

          <h2>Telematics Notice and Release</h2>
          <p>
            Vehicles may have features that monitor the vehicle's condition,
            mileage, location, and other data.
          </p>
          <p>
            You agree to the terms and conditions posted by the vehicle
            manufacturer or technology provider.
          </p>
          <p>
            You release IRIDE from any liability for failure of the features or
            arising from the use of the feature.
          </p>

          <h2>Condition of the Vehicle and Optional Extras</h2>
          <p>You understand that third parties own the vehicles.</p>
          <p>
            Each host is responsible for ensuring their vehicle is safe and
            roadworthy.
          </p>
          <p>
            You are encouraged to complete a visual inspection of the vehicle
            before using it.
          </p>
          <p>
            If there is damage, you should upload photos and report it
            immediately.
          </p>
          <p>
            IRIDE is not responsible for lost or stolen belongings left in the
            vehicle.
          </p>

          <h2>Incident Reporting</h2>
          <p>You must immediately report any damage to the vehicle to IRIDE.</p>
          <p>
            In case of a collision, you must also make a report to the police.
          </p>
          <p>
            You will need to cooperate with any loss investigation conducted by
            IRIDE.
          </p>

          <h2>Vehicle Theft</h2>
          <p>
            The following conduct may result in the vehicle being reported as
            stolen:
          </p>
          <p>Failing to return the vehicle at the agreed-upon time and place</p>
          <p>Not returning the vehicle by the end of the reservation period</p>
          <p>Returning the vehicle to a different location</p>
          <p>Misrepresenting facts to the host</p>
          <p>
            Failing to communicate with the host, police, or IRIDE in case of an
            accident
          </p>
          <p>
            Operating the vehicle with someone who has a fictitious name, false
            address, or invalid driver's license
          </p>
          <p>
            The primary guest who books the reservation is responsible for any
            private investigation costs if the vehicle is not returned.
          </p>

          <h2>Repossession</h2>
          <p>
            IRIDE or the host may repossess the vehicle if it is not returned,
            illegally parked, abandoned, or used in violation of the law.
          </p>

          <h2>Missing Vehicles</h2>
          <p>
            If a vehicle you booked is missing or stolen, you must immediately
            return the original key to the host, file a police report, and
            cooperate with the investigation.
          </p>

          <h2>Specific Terms for Hosts</h2>
          <p>
            As a host, you agree to provide a safe, legally registered, and
            insured vehicle.
          </p>
          <p>
            You will only provide the vehicle to a listed driver on the
            reservation.
          </p>
          <p>
            You will not collect any information from the guest at check-in
            other than their driver's license.
          </p>
          <p>
            You agree to honor all representations made in your listings,
            including the price quoted.
          </p>
          <p>
            You will not offer a vehicle that is subject to a missing vehicle
            report or safety recall.
          </p>
          <p>You will list the vehicle only on IRIDE's platform.</p>
          <p>
            You are responsible for regularly checking your vehicle for defects
            and maintaining it in safe condition.
          </p>
          <p>
            You are required to report any guest-caused damage as soon soon as
            possible.
          </p>
          <p>Normal wear and tear is not covered under any claims.</p>

          <h2>Dispute Resolution</h2>
          <p>
            Any disputes between you and IRIDE will be resolved through binding
            arbitration.
          </p>

          <h2>Governing Law</h2>
          <p>The agreement is governed by the laws of Pakistan.</p>
          <h1>GENERAL PROVISIONS:</h1>
          <h2>TERMINATION</h2>
          <p>
            You may discontinue your use of the Services at any time and IRIDE
            may terminate your access to the Services and remove any listings
            for any reason or no reason to the extent permissible under
            applicable law. Termination of access to the Services will not
            release a Party from any obligations it incurred prior to the
            termination and IRIDE may retain and continue to use any
            information, including but not limited to the values add-on by you.
            Termination of the Agreement will not have any effect on the
            disclaimers, waiver or liability limitations, or legal disputes
            provisions under the Agreement and/or any fees due, and all of those
            terms will survive any termination of the Agreement.
          </p>

          <h2>NO VEHICLE TRANSFER OR ASSIGNMENT:</h2>
          <p>
            Except as otherwise provided herein, guests and hosts agree that
            nothing in these Terms constitutes an actual or purported transfer
            or assignment of any right or interest in a vehicle or optional
            Extras shared through the Services.
          </p>
          <h2>DISCLAIMERS</h2>
          <p>
            IRIDE provides services that enable the sharing of vehicles and
            optional extras between hosts and guests. Except as otherwise
            provided in these terms, IRIDE does not itself provide vehicle
            sharing, rental services, and/or insurance services and is not
            responsible for any of the acts or omissions of any of the users of
            its services, the manufacturer of the vehicle or any optional
            extras, or any third-party Page 12 of 14 provider of services (e.g.
            In-vehicle GPS or other systems). The services are provided “as is”,
            without warranty of any kind, either express or implied. To the
            extent permitted by applicable law, without limiting the foregoing,
            IRIDE explicitly disclaims any warranties of merchantability,
            fitness for a particular purpose, quiet enjoyment, or
            non-infringement, and any warranties arising out of course of
            dealing or usage of trade. Iride makes no warranty that the
            services, including, but not limited to, the listing and/or any
            vehicle or optional extra, will meet your requirements or be
            available on an uninterrupted, secure, or error-free basis. Iride
            makes no warranty regarding the quality of any listings, vehicles,
            hosts, guests, extras, the services, or any content or the accuracy,
            timeliness, truthfulness, completeness, or reliability of any
            content obtained through the services. No advice or information,
            whether oral or written, obtained from IRIDE, or its service
            providers or through the services or content, will create any
            warranty not expressly made herein.
          </p>

          <h2>LIMITATION OF LIABILITY AND WAIVER:</h2>
          <p>
            Except where prohibited by law, you waive and discharge any and all
            rights you have to sue or make claims against IRIDE, IRIDE user for
            any damages or losses, whether due to negligence or otherwise,
            arising out of or in connection with the following: (1) vehicle
            availability (2) problems with a vehicle (e.g., any malfunction of
            or deficiency with a vehicle), (3) vehicle warranty issues (e.g.,
            any breach of warranty or other obligation by any manufacturer or
            other third party associated with the vehicle), (4) the legal or
            license status of a vehicle, host, or guest, or (5) any action or
            inaction of a host or guest.
            <br />
            You agree that neither IRIDE nor any other party involved in
            creating, producing, or delivering the services will be liable for
            any incidental, special, exemplary, or consequential damages
            including lost profits, data, or goodwill, service interruption,
            computer damage or system failure, or the cost of substitute
            products or services. <br />
            The above limitations of liability and waiver provisions are
            fundamental elements of the basis of the bargain between IRIDE and
            you. They shall apply to the extent permitted by applicable law, and
            any aspects of them that are deemed void or unenforceable shall be
            severed while leaving the remainder in effect.
          </p>

          <h2>INDEMNIFICATION</h2>
          <p>
            You agree to release, defend, indemnify, and hold IRIDE against any
            claims, liabilities, damages, losses, and expenses, including,
            without limitation, reasonable legal and accounting fees, arising
            out of or in any way connected with: (1) your access to or use of
            the Services, (2) your violation of these Terms, (3) your user
            content, (4) your interaction with any other user of the Services,
            or (5) your booking of a vehicle or creation of a listing for a
            vehicle. Such indemnification includes but is not limited to any
            injuries, losses, or damages (compensatory, direct, incidental,
            consequential, or otherwise) arising in connection with or as a
            result of a booking, sharing, or use of a vehicle or optional Extra.
            This indemnification provision is a fundamental element of the basis
            of the bargain between IRIDE and you. It shall apply to the extent
            permitted by applicable law, and any aspects of it that are deemed
            unenforceable shall be severed while leaving the remainder in
            effect.
          </p>
          <h2>LIQUIDATED DAMAGES:</h2>
          <p>
            You acknowledge that the actual damages likely to result from your
            breaches of the Agreement by any of the following are difficult to
            estimate accurately and would be difficult for IRIDE to prove with
            Page 13 of 14 certainty: using the IRIDE domains, trademarks, or
            taglines without IRIDE's express consent, including without
            limitation registering website domains or social media handles, or
            bidding on online advertising key words, suing or asserting legal
            claims against the IRIDE Parties or a IRIDE user in any manner
            prohibited or waived herein, as a host, sharing your vehicle via the
            Services with expired registration or without current registration
            paperwork in the vehicle, as a host, offering any vehicle that is
            the subject of a missing or stolen vehicle report, as a host,
            offering any vehicle or releasing a vehicle to a guest that is not
            roadworthy or is otherwise in an unsafe condition, as a host, cancel
            or modify a trip to manipulate the price or potential earnings for a
            vehicle, or creating or using a IRIDE account that has the effect of
            circumventing IRIDE’s suspension, termination, or closure of another
            IRIDE account. You will pay IRIDE PKR Rs. 200,000 per breach in
            Liquidated Damages to compensate IRIDE for any such conduct. This
            amount is not intended as a punishment for any such breach, but
            rather as a reasonable estimate where actual damages are difficult
            estimate accurately and/or prove with certainty. These liquidated
            damages provision is a fundamental element of the basis of the
            bargain between IRIDE and you. It shall apply to the extent
            permitted by applicable law, and any aspects of it that are deemed
            unenforceable shall be severed while leaving the remainder in
            effect.
          </p>
          <h2>NOT A RENTAL CAR COMPANY; NO VEHICLE LEASING”</h2>
          <p>
            IRIDE is not a rental car company. It does not own a fleet of
            vehicles and is not in the business of renting vehicles to the
            public. IRIDE also is not providing short- or long-term vehicle
            leases. IRIDE provides an online platform where vehicle owners and
            those in need of a vehicle can meet and share vehicles amongst
            themselves subject to these Terms.
          </p>
          <h2>ROUNDING OFF; CURRENCY:</h2>
          <p>
            IRIDE may, in its sole discretion, round up or down amounts that are
            payable from or to hosts or guests to the nearest whole functional
            base unit in which the currency is denominated unless explicitly
            prohibited under applicable law.{" "}
          </p>
          <h2>CONTACT US:</h2>
          <p>
            If you have any questions for us concerning the Agreement, or IRIDE
            generally, please contact us at email.{" "}
          </p>
          <h2>TRANSLATIONS</h2>
          <p>
            Where IRIDE has provided you with a translation of the English
            language version of these Terms or any Policies, in case of any
            wording discrepancies between the English and any other versions of
            the Terms and any Policies, the English language wording takes
            precedence.{" "}
          </p>
          <h2>SEVERABILITY AND NON-WAIVER</h2>
          <p>
            In the event any provision of these Terms is held to be void,
            voidable, or unenforceable, the remaining provisions shall remain in
            full force and effect. The failure of any Party to enforce any
            provision of these Terms shall not be construed to be a waiver of
            such provision, or any other provision, nor in any way to affect the
            validity of these Terms or any part of these Terms, or any right of
            any Party to enforce that Page 14 of 14 provision or each and every
            other provision at any time. No waiver of any breach of these Terms
            shall constitute or be deemed a waiver of any other breach
          </p>
          <h2>GENERAL</h2>
          <p>
            IRIDE does not appoint you or any other user as its employee,
            mandatory, legal agent, or form any kind of legal partnership or
            joint venture. You are not authorized to make any commitments on
            behalf of IRIDE and IRIDE will not make commitments on your behalf,
            except as contemplated by the Services or expressly stated in these
            Terms.
          </p>
          <p>
            The Agreement, including these Terms, states the entire
            understanding between you and IRIDE concerning your access to and
            use of the Services and supersedes any earlier verbal or written
            communications between us. With the exception of appointing a
            custodian to manage your vehicles on your behalf, you may not
            delegate your legal obligations or transfer any of your rights, in
            whole or in part, to any third party without advance written consent
            of IRIDE. You will remain responsible for your obligations hereunder
            in any event. A IRIDE director or officer must agree to any
            modification or waiver of any term of the Agreement in writing.
            Headings are for reference purposes only and do not limit the scope
            or extent of such section.
          </p>
        </section>
      </div>
    </>
  );
};

export default TermsAndConditions;
